<template>
  <v-card>
    <v-card-title>
      <span class="headline">Cliente nuevo</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="2" md="3">
            <v-text-field
              dense
              v-model="cli.nombre"
              required
              :rules="[rules.required, rules.min, rules.text]"
              label="* Nombre"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="3">
            <v-text-field
              dense
              v-model="cli.cc"
              :rules="[rules.required, rules.number]"
              label="* C.C."
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="3">
            <v-text-field
              dense
              v-model="cli.usuario"
              :rules="[rules.required]"
              label="* Usuario"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="3">
            <v-text-field
              dense
              v-model="cli.password"
              :rules="[rules.required]"
              label="* Contraseña"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="3">
            <v-text-field
              dense
              v-model="cli.correo"
              :rules="[rules.required, rules.email]"
              label="* Correo"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="3">
            <v-text-field
              dense
              v-model="cli.passCorreo"
              :rules="[rules.required]"
              label="* Contraseña correo"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="3">
            <v-text-field dense v-model="cli.edad" label="Edad"></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="3">
            <v-select
              dense
              :items="['Femenino', 'Masculino']"
              v-model="cli.genero"
              placeholder="Género"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              dense
              v-model="cli.linkDrive"
              label="LINK DRIVE"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="6">
            <VuePhoneNumberInput
              :preferred-countries="preferencias"
              :default-country-code="preferencia"
              clearable
              id="codePhone"
              :color="color"
              :error-color="errorColor"
              :valid-color="validColor"
              required
              @update="getNum"
              :translations="trans"
              v-model="cli.telefono"
            />
          </v-col>
          <v-col cols="12" sm="4" md="6">
            <VuePhoneNumberInput
              :preferred-countries="preferencias"
              :default-country-code="preferencia"
              id="codePhone"
              :color="color"
              :error-color="errorColor"
              :valid-color="validColor"
              required
              @update="getNum2"
              :translations="trans"
              v-model="cli.telefono2"
            />
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete
              v-model="cli.linea"
              :items="lineas"
              item-text="nombre"
              item-value="nombre"
              :append-icon="lineas.length == 0 ? 'mdi-reload' : ''"
              chips
              clearable
              hide-details
              label="Lista de líneas"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>Esta línea no existe</v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" md="4">
            <v-autocomplete
              v-model="cli.cead"
              :items="ceads"
              item-text="nombre"
              item-value="nombre"
              :append-icon="ceads.length == 0 ? 'mdi-reload' : ''"
              chips
              clearable
              hide-details
              :hide-no-data="!newCead"
              :search-input.sync="newCead"
              label="Lista de CEAD"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>Este CEAD no existe</v-list-item-title>
                </v-list-item>
                <v-list-item-action>
                  <v-btn color="primary" text @click="saveCead(newCead)"
                    >Agregar a la lista de CEAD</v-btn
                  >
                </v-list-item-action>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" md="4">
            <v-autocomplete
              v-model="cli.carrera"
              :items="carreras"
              item-text="nombre"
              item-value="nombre"
              :append-icon="carreras.length == 0 ? 'mdi-reload' : ''"
              :hide-no-data="!newCarrera"
              :search-input.sync="newCarrera"
              chips
              clearable
              hide-details
              label="Lista de carreras"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>Esta carrera no existe</v-list-item-title>
                </v-list-item>
                <v-list-item-action>
                  <v-btn color="primary" text @click="saveCarreras(newCarrera)"
                    >Agregar a la lista de carerras</v-btn
                  >
                </v-list-item-action>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="accent" text @click="close">Cancelar</v-btn>
      <v-btn
        :disabled="buscando"
        color="primary"
        @click="cli._id == null ? saveCli() : edit()"
        text
        >Guardar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import firebase from "firebase";
import { host } from "../Tools/variables";
import axios from "axios";
import VuePhoneNumberInput from "vue-phone-number-input";
import data from "../Tools/data";

export default {
  name: "clienteComponent",

  props: ["info"],

  components: {
    VuePhoneNumberInput,
  },

  data() {
    return {
      preferencias: ["CO"],
      preferencia: "CO",
      color: "#2e7d32",
      errorColor: "#b71c1c",
      validColor: "#808080",
      trans: {
        countrySelectorLabel: "Código",
        countrySelectorError: "Error",
        phoneNumberLabel: "No. teléfono",
        example: "Ejemplo :",
      },
      rules: {
        required: (value) => {
          !!value || "Campo requerido.";
        },
        min: (v) => {
          v.length > 2 || "Invalido";
        },
        text: (v) => {
          const req = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
          return req.test(v) || "Solo letras";
        },
        number: (v) => {
          if (v != null) {
            const req = /^[0-9 ]*$/;
            return req.test(v) || "solo numeros";
          }
        },
        email: (v) => {
          if (v != null) {
            const req =
              /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            return req.test(v) || "formato invalido para el correo";
          }
        },
      },
      buscando: false,
      cli: {
        _id: null,
        nombre: null,
        cc: null,
        indi: null,
        telefono: null,
        indi2: null,
        telefono2: null,
        linea: null,
        usuario: null,
        password: null,
        country: null,
        correo: null,
        passCorreo: null,
        cursosMatriculados: [],
        edad: null,
        linkDrive: null,
        cead: null,
        carrera: null,
        genero: null,
        activo: true,
      },
      newCead: null,
      newCarrera: null,
      carreras: [],
      lineas: [],
      ceads: [],
    };
  },
  mounted() {
    if (this.info._id != null) {
      this.cli._id = this.info["_id"];
      this.cli.nombre = this.info["Nombre"];
      this.cli.cc = this.info["Cédula"];
      this.cli.telefono = this.info["Teléfono"];
      this.cli.telefono2 = this.info["Teléfono 2"];
      this.cli.usuario = this.info["Usuario"];
      this.cli.password = this.info["Contraseña"];
      this.cli.correo = this.info["Correo"];
      this.cli.linea = this.info["Linea"];
      this.cli.passCorreo = this.info["Contraseña correo"];
      this.cli.edad = this.info["Edad"];
      this.cli.cead = this.info["CEAD"];
      this.cli.linkDrive = this.info["LINK DRIVE"];
      this.cli.carrera = this.info["Carrera"];
      this.cli.genero = this.info["Género"];
      this.cli.activo = this.info["Activo"];
    }
  },

  async created() {
    // ceads
    if (this.$store.state.ceads.length == 0) {
      this.ceads = await data.dataList("/cead", "ceads", "listar", "ceads");
    } else {
      this.ceads = this.$store.state.ceads;
    }
    // carreras
    if (this.$store.state.carreras.length == 0) {
      this.carreras = await data.dataList(
        "/carreras",
        "carreras",
        "listar",
        "carreras"
      );
    } else {
      this.carreras = this.$store.state.carreras;
    }
    // lineas
    if (this.$store.state.lineas.length == 0) {
      this.lineas = await data.dataList("/lineas", "lista", "listar", "lineas");
    } else {
      this.lineas = this.$store.state.lineas;
    }
  },

  // computed: {
  // ceads: function () {
  //   var info = [];
  //   for (var j = 0; j < this.$store.state.ceads.length; j++) {
  //     info.push(this.$store.state.ceads[j]["nombre"]);
  //   }
  //   return info;
  // },
  // carreras: function () {
  //   var info = [];
  //   for (var u = 0; u < this.$store.state.carreras.length; u++) {
  //     info.push(this.$store.state.carreras[u]["nombre"]);
  //   }
  //   return info;
  // },
  // lineas: function () {
  //   var info = [];
  //   for (var l = 0; l < this.$store.state.lineas.length; l++) {
  //     info.push(this.$store.state.lineas[l]["nombre"]);
  //   }
  //   return info;
  // },
  // },

  methods: {
    getNum(v) {
      this.cli.indi = v.formattedNumber;
    },

    getNum2(v) {
      this.cli.indi2 = v.formattedNumber;
    },

    saveCead(info) {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "agregar");
          fd.append("nombre", info);
          axios.post(host + "/cead", fd).then((result) => {
            let data = result.data;
            if (data.text == "OK") {
              this.ceads.push(info);
              this.$store.commit("add", {
                name: "ceads",
                data: { nombre: info },
              });
            } else {
              this.$alert("No se puede agregar el CEAD");
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    saveCarreras(info) {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "agregar");
          fd.append("nombre", info);
          axios.post(host + "/carreras", fd).then((result) => {
            let data = result.data;
            if (data.text == "OK") {
              this.carreras.push(info);
              this.$store.commit("add", {
                name: "carreras",
                data: { nombre: info },
              });
            } else {
              this.$alert("No se puedo agregar la carrera");
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    saveCli() {
      this.buscando = true;
      if (this.buscando == true) {
        const reqText = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
        const reqTel = /^[0-9 ]*$/;
        const reqEmail =
          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (
          this.cli.nombre != "" &&
          this.cli.telefono != "" &&
          this.cli.cc != "" &&
          this.cli.usuario != "" &&
          this.cli.password != "" &&
          this.cli.correo != "" &&
          this.cli.passCorreo != "" &&
          this.cli.nombre != null &&
          this.cli.telefono != null &&
          this.cli.cc != null &&
          this.cli.usuario != null &&
          this.cli.password != null &&
          this.cli.correo != null &&
          this.cli.passCorreo != null &&
          reqText.test(this.cli.nombre) &&
          reqTel.test(this.cli.telefono) &&
          reqEmail.test(this.cli.correo)
        ) {
          firebase
            .auth()
            .currentUser.getIdToken(/* forceRefresh */ true)
            .then((idToken) => {
              const fd = new FormData();
              fd.append("token", idToken);
              fd.append("nombre", this.cli.nombre);
              fd.append("telefono", this.cli.indi == null ? "" : this.cli.indi);
              fd.append(
                "telefono2",
                this.cli.indi2 == null ? "" : this.cli.indi2
              );
              fd.append("documento", this.cli.cc);
              fd.append("usuario", this.cli.usuario);
              fd.append("password", this.cli.password);
              fd.append("correo", this.cli.correo);
              fd.append("linea", this.cli.linea);
              fd.append("passCorreo", this.cli.passCorreo);
              fd.append(
                "cursosMatriculados",
                this.cli.cursosMatriculados.length == 0
                  ? ""
                  : this.cli.cursosMatriculados
              );
              fd.append("edad", this.cli.edad == null ? "" : this.cli.edad);
              fd.append("cead", this.cli.cead == null ? "" : this.cli.cead);
              fd.append(
                "linkDrive",
                this.cli.linkDrive == null ? "" : this.cli.linkDrive
              );
              fd.append(
                "carrera",
                this.cli.carrera == null ? "" : this.cli.carrera
              );
              fd.append(
                "genero",
                this.cli.genero == null ? "" : this.cli.genero
              );
              fd.append(
                "activo",
                this.cli.activo == null ? "" : this.cli.activo
              );
              axios.post(host + "/nuevoCliente", fd).then((result) => {
                let data = result.data.text;
                this.buscando = false;
                if (data == "OK") {
                  this.$emit("toggle", this.cli);
                  if (result.data.listaNegra == true) {
                    this.$alert("¡CLIENTE EN LISTA NEGRA!");
                  }
                } else {
                  this.$alert(data);
                }
              });
            })
            .catch(function (error) {
              this.buscando = false;
              this.$alert("Error " + error.message);
            });
        } else {
          this.buscando = false;
          this.$alert(
            "No se puede guardar la información por campos requeridos, o campos invalidos."
          );
        }
      }
    },

    edit() {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("idE", this.cli._id);
          fd.append("nombre", this.cli.nombre);
          fd.append("documento", this.cli.cc);
          fd.append("telefono", this.cli.indi == null ? "" : this.cli.indi);
          fd.append("telefono2", this.cli.indi2 == null ? "" : this.cli.indi2);
          fd.append("usuario", this.cli.usuario);
          fd.append("password", this.cli.password);
          fd.append("correo", this.cli.correo);
          fd.append("linea", this.cli.linea);
          fd.append("linkDrive", this.cli.linkDrive);
          fd.append("passCorreo", this.cli.passCorreo);
          fd.append("edad", this.cli.edad);
          fd.append("cead", this.cli.cead);
          fd.append("carrera", this.cli.carrera);
          fd.append("genero", this.cli.genero);
          fd.append("activo", this.cli.activo);
          fd.append("permisoAdmin", "SI");
          axios.post(host + "/editaEstudiante", fd).then((result) => {
            console.log("SAVE" + JSON.stringify(result.data));
            this.$emit("toggle", this.cli);
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    close() {
      this.$emit("toggle", "closeCli");
    },
  },
};
</script>
